@use "rde_colors";
@use "sass/variables";
@use "grid/mixins/breakpoints";
@use "grid/mixins/grid";


// Custom Theming for Angular Material
@use "@angular/material" as mat;
@import '@angular/material/theming';

//import textlink animations file

html, body {
  max-width: 100vw;
  overflow-x: hidden;
}
.staples-connect-box-sizing-wrapper {
  @import "bootstrap/scss/bootstrap"; // I want to get rid of this
  @import "rde_typography";
  @import "textlink_animations";
  * {
    font-family: $staples-connect-font !important;
  }

  mat-icon {
    font-family: 'Material Icons', $staples-connect-font !important;
  }

  //overriding boot strap default values.
  button.sc-button {
    border-radius: 20px;
    @media (min-width: 1024px){
        border-radius: 25px;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-checkbox-layout {
    display: inline-flex;
  }

  form label {
    text-transform: none;
  }
}

// import variable file
// For more information: https://material.angular.io/guide/theming
@import "@angular/material/theming";
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();
//@import "tt_norms-fonts";
@import "staples_norms-font"; //why is this needed.



// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$StaplesRetailExperience-primary: mat.define-palette(variables.$mat-staples-red);
$StaplesRetailExperience-accent: mat.define-palette(variables.$mat-staples-red, A200, A100, A400);

// The warn palette is optional (defaults to red).
$StaplesRetailExperience-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$StaplesRetailExperience-theme: mat.define-light-theme(
  $StaplesRetailExperience-primary,
  $StaplesRetailExperience-accent,
  $StaplesRetailExperience-warn
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($StaplesRetailExperience-theme);


.staples-connect-box-sizing-wrapper {
  form label {
    font-family: variables.$staples-connect-font !important;
  }

.mat-mdc-option {
    font-family: variables.$staples-connect-font !important;
 }

  $custom-typography: mat-typography-config(
  $font-family: variables.$staples-connect-font
);

@include angular-material-typography($custom-typography);
}
/* You can add global styles to this file, and also import other style files */

$custom-typography: mat-typography-config(
  $font-family: variables.$staples-connect-font
);

@include angular-material-typography($custom-typography);
html, body {
  //margin: 0 0 0 0;
  //font-family:$staples-connect-font;
  //background-color: rde_colors.$white;
  margin: unset;
  font-family: variables.$staples-connect-font;
  font-size: unset;
  font-weight: unset;
  line-height: 1;
  text-align: unset;
  background-color: unset;
  color: unset;
}

div.staples-connect-box-sizing-wrapper div,
div.staples-connect-box-sizing-wrapper span,
div.staples-connect-box-sizing-wrapper source,
div.staples-connect-box-sizing-wrapper .mat-form-field,
div.staples-connect-box-sizing-wrapper .mat-select,
div.staples-connect-box-sizing-wrapper .mat-checkbox,
div.staples-connect-box-sizing-wrapper .mat-radio-button {
  font-family: variables.$staples-connect-font;
}

.opacity0 {
  opacity: 0;
}

@for $i from 1 through 100 {
    .opacity-#{$i} {
      opacity: #{$i * 1%};
    }
}

.menu-spacer {
  flex: 1 1 auto;
}

.staplesToolBar {
  background-color: white;
  color: rde_colors.$staples-red;
  border-bottom: rde_colors.$button-inactive solid 1px;
}

button:focus {
  outline: none;
}

.width-100 {
  width: 100%;
}

.width-80 {
  width: 80%;
}

.width-30 {
  width: 30%;
}

.box-shadow-5ae676 {
  @include breakpoints.media-breakpoint-between(xs, sm) {
    box-shadow: 0 5px variables.$SC-working;
  }

  @include breakpoints.media-breakpoint-up(md) {
    box-shadow: 0 5px variables.$SC-working;
  }
}

.box-shadow-b80cc5 {
  @include breakpoints.media-breakpoint-between(xs, sm) {
    box-shadow: 0 5px rde_colors.$usr-purple;
  }

  @include breakpoints.media-breakpoint-up(md) {
    box-shadow: 0 5px rde_colors.$usr-purple;
  }
}

.box-shadow-ffec00 {
  @include breakpoints.media-breakpoint-between(xs, sm) {
    box-shadow: 0 5px rde_colors.$usr-yellow;
  }

  @include breakpoints.media-breakpoint-up(md) {
    box-shadow: -0px 5px rde_colors.$usr-yellow;
  }
}

.box-shadow-f3f3f3 {
  @include breakpoints.media-breakpoint-between(xs, sm) {
    box-shadow: 0 5px rde_colors.$extra-light-gray;
  }
  @include breakpoints.media-breakpoint-up(md) {
    box-shadow: -0px 5px rde_colors.$extra-light-gray;
  }
}

.box-shadow-cc0000 {
  @include breakpoints.media-breakpoint-between(xs, sm) {
    box-shadow: 0 5px rde_colors.$staples-red;
  }
  @include breakpoints.media-breakpoint-up(md) {
    box-shadow: -0px 5px rde_colors.$staples-red;
  }
}

.box-shadow-0192DB {
  @include breakpoints.media-breakpoint-between(xs, sm) {
    box-shadow: 0 5px rde_colors.$text-link;
  }

  @include breakpoints.media-breakpoint-up(md) {
    box-shadow: -0px 5px rde_colors.$text-link;
  }
}

.box-shadow-444444 {
  @include breakpoints.media-breakpoint-between(xs, sm) {
    box-shadow: 0 5px rde_colors.$medium-gray;
  }

  @include breakpoints.media-breakpoint-up(md) {
    box-shadow: -0px 5px rde_colors.$medium-gray;
  }
}

.background-white {
  background-color: rde_colors.$white;
}

.background-dark-grey {
  background-color: rde_colors.$dark-gray;
}

.background-red {
  background-color: rde_colors.$staples-red;
}

.background-light-grey {
  background-color: rde_colors.$extra-light-gray;
}

.background-learning-yellow {
  background-color: rde_colors.$usr-yellow;
}

.background-working-green {
  background-color: variables.$SC-working;
}

.background-small-business-purple {
  background-color: rde_colors.$usr-purple;
}

.background-health-and-safety-blue {
  background-color: rde_colors.$usr-blue;
}

.background-color-5ae676 {
  background-color: variables.$SC-working;
}

.background-color-b80cc5 {
  background-color: rde_colors.$usr-purple;
}

.background-color-ffec00 {
  background-color: rde_colors.$usr-yellow;
}

.background-color-444444 {
  background-color: rde_colors.$medium-gray;
}

.background-color-0192DB {
  background-color: rde_colors.$text-link;
}

.background-color-f3f3f3 {
  background-color: rde_colors.$extra-light-gray;
}

.background-color-cc0000 {
  background-color: rde_colors.$staples-red;
}

.background-color-transparent {
  background-color: transparent;
}

.font-black {
  color: rde_colors.$dark-gray;
}

.font-dark-grey {
  color: rde_colors.$light-gray;
}

.font-white {
  color: rde_colors.$white;
}

.font-red {
  color: rde_colors.$staples-red;
}

.font-light-grey {
  color: rde_colors.$button-inactive;
}

.font-blue {
  color: rde_colors.$text-link;
}

/*
Style for Superscript directive
 */
[rdeSuperScript] > sup.rdeSuperScript {
  font-size: 50%;
  top: -0.6em;
}

.deal-wrapper {
  [rdeSuperScript] > sup.rdeSuperScript {
    top: -0.8em;
  }
}


//fix for caret moving to second line
a.blue-link-caret::after {
  position: relative;
}

.black-link-caret::after {
  position: relative;
}

.white-link-caret::after {
  position: relative;
}

.font-size-20px {
  font-size: 20px;
}

.font-size-18px {
  font-size: 18px;
}

.font-size-16px {
  font-size: 16px;
}

.font-size-14px {
  font-size: 14px;
}

.font-size-12px {
  font-size: 12px;
}

.font-size-10px {
  font-size: 10px;
}

.font-size-64px {
  font-size: 64px;
  line-height: 68px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-outline-start {
  border-radius: 28px 0 0 28px !important;
  min-width: 28px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-outline-end {
  border-radius: 0 28px 28px 0 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-outline .mat-form-field-infix {
  margin-left: 24px;
  margin-right: 24px;
  width: 150px;
  padding: 10px 0 20px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-prefix + .mat-form-field-infix {
  margin-left: 8px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-outline .mat-form-field-suffix {
  top: 8px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-outline .mat-form-field-prefix {
  top: 0 !important;
  width: 16px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-subscript-wrapper {
  position: relative !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-label-wrapper {
  top: -14px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  background-color: rde_colors.$button-inactive;
  border-radius: 28px;
  border: 1px solid rde_colors.$light-gray;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-mdc-radio-button:not(mat-radio-disabled).cdk-program-focused .mat-radio-persistent-ripple {
  opacity: 0 !important;
}

mat-form-field {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  &.mat-form-field-appearance-outline.read-only-form-field .mat-form-field-outline {
    color: rgba(0, 0, 0, 0.12);
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  &.mat-form-field-appearance-outline.read-only-form-field .mat-form-field-outline-thick .mat-form-field-outline-start,
  &.mat-form-field-appearance-outline.read-only-form-field .mat-form-field-outline-thick .mat-form-field-outline-end,
  &.mat-form-field-appearance-outline.read-only-form-field .mat-form-field-outline-thick .mat-form-field-outline-gap {
    border-width: 1px !important;
  }
}

.read-only-form-field {
  mat-label {
    color: rde_colors.$light-gray;
  }

  input {
    color: rde_colors.$light-gray;
  }
}

.staples-connect-box-sizing-wrapper label {
  display: flex;
}

.screaming-offer-banner-color-dark-gray{
  color: rde_colors.$dark-gray;
}

.screaming-offer-banner-color-light-gray{
  color: rde_colors.$medium-gray;
}

.screaming-offer-banner-color-red{
  color: rde_colors.$staples-red;
}

.screaming-offer-banner-color-green{
  color: variables.$staples-green-open;
}

.screaming-offer-banner-color-blue{
  color: rde_colors.$text-link;
}

.screaming-offer-banner-color-flag-sale{
  color: variables.$staples-yellow;
}

 .screaming-offer-banner-color-turning-sign{
  color: rde_colors.$usr-purple;
}

 .screaming-offer-banner-color-learning{
  color: rde_colors.$usr-yellow;
}

.screaming-offer-banner-color-remote-ready{
  color: rde_colors.$usr-green;
}

.screaming-offer-banner-color-white{
  color: rde_colors.$white;
}

.cbd-rich-text-editor {
  img {
    max-width: 100%;
  }

  ul {
    list-style-type: disc;

    li {
      line-height: unset;
    }
  }

  ul li::before {
    content: "";
  }
}


button.gm-ui-hover-effect {
  width: 40px !important;
  height: 40px !important;
}
.gm-style .gm-style-iw-c {
  border-radius: 0;
}

.cbd-listing-info-window {
  display: block;
  text-decoration: none;
  .business-container {
    display: flex;
    max-width: 100%;
    align-items: flex-start;
    padding: 0 0 5px 5px;
    flex-direction: row;
  }
  .business-image-map{
    @include breakpoints.media-breakpoint-between(xs,sm) {
      height: 80px;
      width: 209px;
    }
}

  .business-image {
    @include grid.make-col(12);
    width: 100%;
    object-fit: cover;



    @include breakpoints.media-breakpoint-up(md) {
      height: 80px;
      width: 119px;
      @include grid.make-col(3, 8);
    }

    @include breakpoints.media-breakpoint-up(lg) {
      height: 110px;
      width: 166px;
      @include grid.make-col(4);
      flex: 0 0 33%;
      position: relative;
      top: 5px;
    }

    @include breakpoints.media-breakpoint-up(xl) {
      height: 160px;
      width: 240px;
      @include grid.make-col(5);
    }
  }
  .business-content {

    @include breakpoints.media-breakpoint-up(lg) {
      @include grid.make-col(8);
      flex: 0 0 calc(66% - 50px);

    }

    @include breakpoints.media-breakpoint-up(xl) {
      @include grid.make-col(7);
    }
  }
  &:focus-visible {
    outline: 0;
  }
  &:hover{
    text-decoration: none;
  }
  .business-name, .business-address, .business-message {
    padding-left: 20px;
  }
}

sc-checkbox .checkbox {
  align-items: start;
}

.filter-item .mat-expansion-panel-body {
  padding: 0;
}

.staples-connect-box-sizing-wrapper{
  margin: 0 0 0 0;
  font-family: variables.$staples-connect-font;
  background-color: rde_colors.$white;

  *, *::before, *::after{
    box-sizing: content-box;
  }

  .scrollbar {
    scrollbar-width: thin;
    scrollbar-height: thin;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: rde_colors.$medium-gray;
    border-radius: 10px;
  }

  .innerHtmlImage {
    img {
      max-width: 100%;

    }

  ul {
    list-style-type: disc;

    li {
      line-height: 28px;
    }
  }

  ul li::before {
    content: none;
  }
  }
}


//a:hover {
//  color: unset;
//}
/**
  Styles to address staples.com integration
 */
.navigation-mega-menu__flyout-menu {
  .navigation-text__navigationText:hover{
    color: #000;
  }
}

.header-nav-sub-wrapper__headerSubWrapper{
  z-index: 2; // RDE-3521
}


.staples-connect-margin-wrapper {
  margin: 0 -12px;
}

.self-serve-top-selector {
  .mat-form-field-appearance-outline .mat-form-field-outline {
    color: black;
  }
}

.check-in-form {
  .mat-step-label {
    white-space: wrap;
  }

  .mat-vertical-content-container {
    margin-left: 0;
  }
}

.expanded-promo-card .mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 15px;

  @include breakpoints.media-breakpoint-between(xs, sm) {
    border-radius:  15px 15px 0 0;
  }
}

.expanded-promo-card {
  position: fixed;
}

.cdk-overlay-container {
  z-index: 10000;
}

