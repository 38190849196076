@import "@angular/material/theming";

$mat-staples-red: (
  50: #f6e0e0,
  100: #e9b3b3,
  200: #da8080,
  300: #cb4d4d,
  400: #c02626,
  500: #b50000,
  600: #ae0000,
  700: #a50000,
  800: #9d0000,
  900: #8d0000,
  A100: white,
  A200: #747474,
  A400: #585858,
  A700: #4d4d4d,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: $white-87-opacity,
    900: $white-87-opacity,
    A100: $black-87-opacity,
    A200: white,
    A400: white,
    A700: white,
  )
);
/* Available Colors
rde_colors.$staples-red;: #CC0000;
$medium-red: #8D0101;
$dark-red: #7D0000;
$darkest-red: #470000;

$ez-plum: #5B0069;
$ez-violet: #3E009F;
$usr-purple: #B80CC5;
$light-yellow: #F2FD08;
$usr-yellow: #FECE27;
$medium-orange: #FF911F;
$usr-green: #5AE676;
$dark-green: #10290A;

$white: #FFF;
$extra-light-gray: #F2F2F2;
$button-inactive: #D3D3D3;
$light-gray: #9A9A9A;
$medium-gray: #585858;
$dark-gray: #343434;
$button-hover: #242424;
$black: #000;

$usr-blue: #0FBAFF;
$medium-blue: #2695CC;
$text-link: #0192DB;
$text-link-hover: #006DA3;
$ez-blue: #086DD2;
$dark-blue: #0B2765;
*/


/* Colors that don't have a direct map to the new color palate */
$staples-gold: #FDB813;// $medium-orange: #FF911F;
$staples-eco-friendly: #01AA6C;// $usr-green: #5AE676;
$staples-yellow: #ECE81A;// $light-yellow: #F2FD08;
$staples-gray-18: #e6e6e6;// $extra-light-gray: #F2F2F2;
$SC-working: #81C671;// $usr-green: #5AE676;
$staples-green-open: #85C752;// $usr-green: #5AE676;
$staples-eco-friendly-2: #D9F2E9;// $usr-green: #5AE676;
$staples-wiseher-2: #E6F0F7;// $medium-blue: #2695CC;
$staples-chamber-of-commerce: #FAE6E6;// $staples-red: #CC0000;
$staples-red-closed: #E2315B;// $staples-red: #CC0000;
$staples-grey-shade: rgba(255, 255, 255, 0.7);
$black-opacity: rgba(0,0,0,0.15);
$staples-light-grey-opacity: rgba(243, 243, 243, 0.6); // With opacity
$staples-highlight-yellow: #FFEC00;
$staples-white-grey: #F8F8F8;

$staples-tt-norms: TT Norms;
$staples-connect-font: STAPLES Norms;
$staples-connect-pro-font: STAPLES Norms Pro;
$staples-max-width: 1296px;
$x-large-screen: 1440px;
$large-screen: 1024px;
$medium-screen: 768px;
$small-screen: 320px;
$lg-cols: 12;
$md-cols: 8;
$sm-cols: 4;
