

// Regular
@font-face {
    font-family: 'STAPLES Norms';
    src: url('../assets/fonts/StaplesNormsPro-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  //Bold
  @font-face {
    font-family: 'STAPLES Norms';
    src: url('../assets/fonts/StaplesNormsPro-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  //Bold Italic
  @font-face {
    font-family: 'STAPLES Norms';
    src: url('../assets/fonts/StaplesNormsPro-BoldItalic.otf') format('opentype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }
  
  //Italic
  @font-face {
    font-family: 'STAPLES Norms';
    src: url('../assets/fonts/StaplesNormsPro-NormalItalic.otf') format('opentype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }
  
  // Medium
  @font-face {
    font-family: 'STAPLES Norms';
    src: url('../assets/fonts/StaplesNormsPro-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  
  // Medium Italic
  @font-face {
    font-family: 'STAPLES Norms';
    src: url('../assets/fonts/StaplesNormsPro-MediumItalic.otf') format('opentype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
  }
  
  // Extra Bold
  @font-face {
    font-family: 'STAPLES Norms';
    src: url('../assets/fonts/StaplesNormsPro-Extrabold.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
  }
  
  // Extra bold Italic
  @font-face {
    font-family: 'STAPLES Norms';
    src: url('../assets/fonts/StaplesNormsPro-ExtraboldItalic.otf') format('opentype');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
  }